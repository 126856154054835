@import 'material-icons/iconfont/material-icons.css';

/* You can add global styles to this file, and also import other style files */
@import '@fontsource/open-sans';

body,
html {
  height: 100%;
}

body {
  margin: 0;
}
